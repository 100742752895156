<template>
  <div @mousedown="close" v-if="value" class="modal" tabindex="-1">
    <div @mousedown.stop class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ title && title !== '' ?  title : 'Confirm' }}</h5>
          <button @click="close" type="button" class="btn-close"></button>
        </div>
        <div class="modal-body">
          <div class="my-3">
            <div class="mb-2">{{ message && message !== '' ? message : 'Are you sure?' }}</div>
            <input
              v-model.trim="promptText"
              type="text"
              class="form-control"
              placeholder="Insert here..."
            >
            <div class="form-text">
              Please refer to the Stripe dashboard to find the subscription ID.<br>
              Example: sub_xxxxxxxxxxxx
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="confirm"
            :disabled="isButtonDisabled"
            type="button"
            class="btn btn-primary"
          >Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
    },
    message: {
      type: String,
    },
  },
  computed: {
    isButtonDisabled() {
      return this.promptText === '';
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    confirm() {
      this.$emit('confirm', this.promptText);
    },
  },
  data() {
    return {
      promptText: '',
    };
  },
};
</script>
